<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task pb-0 mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$bvModal.show('add-test-case-modal')"
              @addTest="$emit('addTest')"
            >
              Create Test Case
            </b-button>
          </div>
          <div class="mx-2 mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              block
              @click="$bvModal.show('import-tests-modal')"
              @addTest="$emit('addTest')"
            >
              Import Test Cases
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <b-list-group class="list-group-filters">
              <!-- All Tests -->
              <b-list-group-item
                :to="{ name: 'model_tests' }"
                :active="isDynamicRouteActive({ name: 'model_tests' })"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">All Tests</span>
              </b-list-group-item>

              <!-- Watch List -->
              <!--<b-list-group-item-->
              <!--  :to="{ name: 'model_tests_watch', params: { watch: true } }"-->
              <!--  :active="isDynamicRouteActive({ name: 'model_tests_watch', params: { watch: true } })"-->
              <!--  @click="$emit('close-left-sidebar')"-->
              <!--&gt;-->
              <!--  <feather-icon-->
              <!--    icon="StarIcon"-->
              <!--    size="18"-->
              <!--    class="mr-75"-->
              <!--  />-->
              <!--  <span class="align-text-bottom line-height-1">Watch List</span>-->
              <!--</b-list-group-item>-->
            </b-list-group>

            <!-- Test Status -->
            <div class="mt-2 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Status
              </h6>
            </div>
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in testFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                  :class="filter.color"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Test Priority -->
            <div class="mt-2 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Priority
              </h6>
            </div>
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="priority in priorityOptions"
                :key="priority.title + $route.path"
                :to="priority.route"
                :active="isDynamicRouteActive(priority.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="priority.icon"
                  size="18"
                  class="mr-75"
                  :class="`text-${priority.color}`"
                />
                <span>{{ priority.title }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
    <test-case-add @add="$emit('addTest')" />
    <ImportTests />
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import TestCaseAdd from '@/views/Tests/TestCaseAdd.vue'
import ImportTests from '@/views/Tests/Modals/ImportTests.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    TestCaseAdd,
    ImportTests,
  },
  props: {
    priorityOptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const testFilters = [
      {
        title: 'Passed',
        icon: 'CheckIcon',
        color: 'text-success',
        route: { name: 'model_tests_filter', params: { filter: 'passed' } },
      },
      {
        title: 'Failed',
        icon: 'SlashIcon',
        color: 'text-danger',
        route: { name: 'model_tests_filter', params: { filter: 'failed' } },
      },
      {
        title: 'Untested',
        icon: 'MinusIcon',
        color: 'text-secondary',
        route: { name: 'model_tests_filter', params: { filter: 'untested' } },
      },
    ]

    return {
      perfectScrollbarSettings,
      testFilters,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
