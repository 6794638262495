<template>
  <div>
    <b-modal
      id="import-tests-modal"
      :key="key"
      title="Import Test Cases from CSV file"
      :size="modalSize"
      no-close-on-backdrop
      hide-footer
      @show="onShow"
      @close="key++"
    >
      <div v-if="loading">
        <div class="modal-body">
          <h1 class="mt-4 font-medium-3 text-center">
            Loading Import Dropdown...
          </h1>
          <h5 class="text-center text-warning">
            <b-spinner />
          </h5>
        </div>
      </div>
      <form-wizard
        v-else
        color="#7367F0"
        :title="null"
        :subtitle="null"
        style="box-shadow: none"
        :finish-button-text="finishButtonText"
        back-button-text="Previous"
        @on-complete="bulkImportTestCases"
      >
        <!-- Step One : Select file -->
        <tab-content
          title="Select File"
          icon="feather icon-file-text"
          :before-change="fileValidator"
        >
          <p class="font-medium-1 mt-0">
            Select CSV file to import
          </p>
          <b-form-file
            v-model="file"
            size="lg"
            accept=".csv"
            invalid-feedback="test"
            :state="Boolean(file)"
            @input="fileLinked()"
          />
        </tab-content>

        <!-- Step Two : Choose the Configuration to import to -->
        <tab-content
          title="Select Configuration"
          icon="feather icon-clipboard"
          :before-change="changeModalSize"
        >
          <p class="font-medium-1 mt-0">
            Select a Configuration to import into
          </p>
          <ConfigurationPicker v-model="configuration" />
        </tab-content>

        <!-- Step Three : Map CSV columns to Kompozition properties -->
        <tab-content
          title="Column Mapping (WIP)"
          icon="feather icon-link"
        >
          <div v-if="file">
            <div class="mt-2">
              <span class="font-medium-2 font-weight-bold">Preview</span>
            </div>

            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              style="max-height: 40vh"
            >
              <b-table
                :items="tableData"
                :fields="fields"
              />
            </vue-perfect-scrollbar>
          </div>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>

<script>
import ConfigurationPicker from '@/views/Tests/Forms/ConfigurationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import csv from 'jquery-csv'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions } from 'vuex'

export default {
  name: 'Import',
  components: {
    ConfigurationPicker,
    FormWizard,
    TabContent,
    VuePerfectScrollbar,
  },
  data() {
    return {
      file: null,
      csv_array: [],
      has_header_row: true,
      configuration: null,
      hidden_properties: [],
      mandatory_properties: [],
      requirement_fields: [],
      bulk_create_loading: false,
      modalSize: 'lg',
      get_default_spec: true,
      loading: false,
      fields: [],
      tableData: [],
      key: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
      perfectScrollbarSettingsMappings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
        suppressScrollX: true,
      },
    }
  },
  computed: {
    properties() {
      return this.requirement_fields.filter(field => !this.hidden_properties.includes(field)).map(field => Object({
        value: field,
        text: field.replaceAll('_', ' '),
        disabled: this.fields.map(({ property }) => property).includes(field),
      }))
    },
    columns() {
      const mapFunction = (text, value) => (this.has_header_row ? { value, text } : { value, text: `Column - ${value}` })
      if (!this.csv_array[0]) return []
      return [{ value: null, text: 'No Value' }, ...this.csv_array[0].map(mapFunction)]
    },
    requirements() {
      const requirements = []
      if (this.configuration !== null) {
        this.csv_array.forEach((row, index) => {
          if (this.has_header_row && index === 0) {
            return
          }
          const requirement = {}
          this.fields.forEach(({ property, column }) => {
            requirement[property] = row[column] || null
          })
          requirements.push(requirement)
        })
      }
      return requirements
    },
    testCases() {
      if (this.csv_array) {
        // console.log(this.requirements)
        console.log(this.csv_array)
        return this.csv_array
      }
    },
    finishButtonText() {
      if (this.bulk_create_loading) {
        return 'Importing CSV Test Cases...'
      }
      return `Import ${this.requirements.length} Test Cases`
    },
  },
  methods: {
    ...mapActions({
      bulkAddRequirements_vuex: 'requirements/bulkAddRequirement',
    }),
    onShow() {
      this.loading = true
      this.loading = false
    },
    fileValidator() {
      return new Promise((resolve, reject) => {
        if (this.file) {
          return resolve(true)
        }
        return reject()
      })
    },
    changeModalSize() {
      this.modalSize = 'xl'
      return true
    },
    bulkAddRequirements() {
      this.bulk_create_loading = true
      this.bulkAddRequirements_vuex({ requirements: this.requirements, specification: this.specification.id }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.has_header_row ? this.csv_array.length - 1 : this.csv_array.length} Requirements Added`,
            icon: 'CheckIcon',
            text: 'Requirements Added',
            variant: 'success',
          },
        })
        this.bulk_create_loading = false
        setTimeout(() => { this.$store.dispatch('tests/getTests') }, 500)
        this.$bvModal.hide('import-requirements-modal')
      })
    },
    bulkImportTestCases() {
      this.bulk_create_loading = true
      const payload = {
        model: this.$store.state.model.id,
        config: this.configuration.id,
        tests: this.csv_array,
      }

      console.log({ payload })
      this.$store.dispatch('tests/importTests', payload)
        .then(({ data }) => {
          // console.log({ data })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Test Cases Import Successful',
              icon: 'CheckIcon',
              text: `Added ${data.length} Test Cases`,
              variant: 'success',
            },
          })
          this.bulk_create_loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Test Cases...',
              icon: 'ClockIcon',
              text: '',
              variant: 'warning',
            },
          })
          this.$store.dispatch('tests/getTests')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Test Cases Loaded',
                  icon: 'CheckIcon',
                  text: 'Test Case list has been successfully refreshed.',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('import-tests-modal')
            })
        })
    },
    browserSupportFileUpload() {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    },
    fileLinked() {
      if (!this.browserSupportFileUpload()) {
        alert('The File APIs are not fully supported in this browser!')
      } else {
        const reader = new FileReader()
        console.log(this.file)
        if (this.file) {
          reader.readAsText(this.file)
          reader.onload = evt => {
            this.csv_array = csv.toArrays(evt.target.result)
            if (this.has_header_row && this.csv_array[0]) {
              this.fields = this.csv_array[0].filter(colNm => !this.requirement_fields.includes(colNm.toLowerCase())).map(x => x.toLowerCase())
              this.tableData = this.parseCSV(evt.target.result)
              console.log(this.tableData)
            }
          }
        } else {
          this.csv_array = []
        }
      }
    },
    parseCSV(csvText) {
      const rows = csvText.split('\n');
      if (!rows || rows.length < 2) {
        return [];
      }
      const headers = rows[0].split(',').map(header => header.trim().toLowerCase());
      const data = [];
      for (let i = 1; i < rows.length; i++) {
        const values = rows[i].split(',');
        if (values.length === headers.length) {
          const obj = {};
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = values[j];
          }
          data.push(obj);
        }
      }
      return data;
    },
  },
}
</script>
